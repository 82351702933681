<script>
export default {
	props: {
		isMember: Boolean,
	},
}
</script>
<template>
	<div>
		<div class="relative pb-5 w100">
			<div class="slide-txt w100 mr-16">
				<span class="font-6 font-md-12" v-if="!isMember" @click="$emit('click:action-btn')">
					HACETE MIEMBRO - HACETE MIEMBRO - HACETE MIEMBRO - HACETE MIEMBRO - HACETE MIEMBRO - HACETE
					MIEMBRO - HACETE MIEMBRO - HACETE MIEMBRO - HACETE MIEMBRO
				</span>
				<span class="font-6 font-md-12" v-else>
					MIRÁ NUESTROS BENEFICIOS - MIRÁ NUESTROS BENEFICIOS - MIRÁ NUESTROS BENEFICIOS - MIRÁ NUESTROS
					BENEFICIOS - MIRÁ NUESTROS BENEFICIOS - MIRÁ NUESTROS BENEFICIOS - MIRÁ NUESTROS BENEFICIOS -
					MIRÁ NUESTROS BENEFICIOS
				</span>
			</div>
		</div>
	</div>
</template>
<style>
.slide-txt {
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
}
.slide-txt:hover {
	color: var(--v-gold-base);
}
.slide-txt span {
	display: inline-block;
	animation: slideTxt 20s linear infinite;
}
.slide-txt span:hover {
	animation: slideTxt 50s linear infinite;
}

@keyframes slideTxt {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-50%, 0);
	}
}
</style>
