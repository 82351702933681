<script>
export default {
	data() {
		return {
			products: this.$srv('TW_D_OurSelection.products', []),
			title: this.$srv('TW_D_OurSelection.title'),
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	mounted() {
		if (this.products.length) {
			this.$eventer.trigger('product-list:view', {
				products: this.products,
				listId: 'la-whiskeria-products',
			})
		}
	},
}
</script>
<template>
	<section v-if="title && products.length" class="coral pb-15">
		<SectionTitle :title="title" />
		<Container width="100%" max-width="1024px">
			<v-row>
				<v-col cols="12" sm="6" md="4" v-for="(product, i) of products" :key="i" class="pa-15 hover">
					<HomeProductCard
						:product="product"
						:class="(i === 1 || i === 4) && !isMobile && 'translate'"
						list-id="la-whiskeria-products"
						:list-index="i"
					/>
				</v-col>
			</v-row>
		</Container>
		<div class="text-center mt-15">
			<WButton x-large @click="$shop.goShop()">Ver mas</WButton>
		</div>
	</section>
</template>

<style scoped>
.translate {
	transform: translateY(5rem);
}
.hover:hover {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
</style>
