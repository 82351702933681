<template>
	<v-card tile class="h100">
		<v-stepper
			v-model="step"
			elevation="0"
			tile
			color="white"
			:class="{ h100: fullHeight }"
			style="overflow: auto"
		>
			<Container
				width="650"
				class="d-flex flex-column relative pt-12 pt-md-0"
				:class="{ h100: fullHeight }"
			>
				<div class="tools d-flex justify-space-between align-center">
					<v-btn text large v-if="step > 0" @click="step--">
						<v-icon color="gold" left>mdi-arrow-left</v-icon>
						Atras
					</v-btn>
					<span></span>
					<v-dialog max-width="600" v-model="stopDialog">
						<template #activator="{ attrs, on }">
							<v-btn icon x-large v-bind="attrs" v-on="on">
								<v-icon x-large>mdi-close</v-icon>
							</v-btn>
						</template>
						<v-card class="text-center px-4 py-8">
							<div class="font-5 ff-1-regular text-uppercase mb-2">
								¿Estas seguro que queres perderte todos los descuentos por ser miembro?
							</div>
							<div class="d-flex justify-center align-center mt-6">
								<div class="d-flex flex-column">
									<WButton @click="stopDialog = false" large class="px-2" x-large>
										Quiero continuar
									</WButton>
									<v-btn text @click="close">Salir</v-btn>
								</div>
							</div>
						</v-card>
					</v-dialog>
				</div>
				<v-stepper-items :class="{ h100: fullHeight, 'pt-14': !fullHeight }">
					<!-- <v-stepper-content :step="0" class="h100">
						<div class="center-y">
							<div class="d-flex flex-column justify-center align-center text-center">
								<div class="pb-7 pb-md-14">
									<Media src="../assets/isologo.svg"></Media>
								</div>
								<div class="ff-1-medium font-10 font-md-14 pb-7 pb-md-14">
									WHISKY BOUTIQUE QUIERE CONOCERTE
								</div>
								<div class="text-uppercase ff-1-regular font-2 font-md-4 pb-7 pb-md-14">
									completá ESTE FORMULARIO Y DEJANOS TUS DATOS PARA PODER OFRECERTE BENEFICIOS
									PENSADOS EXCLUSIVAMENTE PARA VOS
								</div>
							</div>
							<div class="d-flex justify-center align-center">
								<WButton @click="step = 1" large class="px-2"> Empezar </WButton>
							</div>
						</div>
					</v-stepper-content> -->
					<v-stepper-content
						class="h100"
						v-for="({ question, options, input }, i) of questions"
						:step="i"
						:key="i"
					>
						<div :class="{ 'center-y': fullHeight }">
							<div class="d-flex flex-column justify-center align-center">
								<div class="gold--text question text-uppercase mb-2">{{ question }}</div>
								<div class="mb-4">{{ step + 1 }} / {{ questions.length }}</div>
								<ul v-if="options" class="text-center pl-0">
									<li
										v-for="(option, j) of options"
										:key="j"
										@click="selectOption(i, j)"
										class="text-uppercase py-2 option"
										:class="{ active: questions[i].active == j }"
									>
										{{ option }}
									</li>
								</ul>
								<div v-if="input" class="d-flex align-center justify-center w100">
									<div
										class="text-uppercase py-2 option mr-4"
										:class="{ active: questions[i].active == 'input' }"
										@click="selectOption(i, 'input')"
									>
										{{ input.label }}
									</div>
									<div class="w100 py-3">
										<v-text-field
											:class="{
												'v-input--is-focused primary--text': questions[i].active == 'input',
											}"
											outlined
											:type="input.type || 'text'"
											v-model="input.value"
											@input="selectOption(i, 'input')"
											@focus="selectOption(i, 'input')"
											hide-details
											dense
										/>
									</div>
								</div>
							</div>
							<div class="d-flex justify-center align-center pt-6">
								<v-tooltip bottom :disabled="!!questions[i].res">
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<WButton @click="step++" :disabled="!questions[i].res"> Siguiente </WButton>
										</div>
									</template>
									<span class="font-0">Complete el paso actual para continuar</span>
								</v-tooltip>
							</div>
						</div>
					</v-stepper-content>
					<v-stepper-content :step="questions.length" :class="{ h100: fullHeight }">
						<div :class="{ 'center-y': fullHeight }">
							<TW-Form-LastStep :questions="questions" />
						</div>
					</v-stepper-content>
				</v-stepper-items>
			</Container>
		</v-stepper>
		<v-snackbar v-model="snackbarError">
			Error al crear la membresia por favor intente mas tarde.
			<template #action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="snackbarError = false"> Cerrar </v-btn>
			</template>
		</v-snackbar>
	</v-card>
</template>

<script>
function formatConfig(data) {
	return data.map(({ question, answerOptions, includeCustomAnswer, customAnswerLabel }) => {
		let obj = {
			question,
			options: answerOptions,
			res: '',
			active: null,
		}
		if (includeCustomAnswer) {
			obj.input = { label: customAnswerLabel, value: '' }
		}
		return obj
	})
}
export default {
	props: {
		opened: Boolean,
	},
	data() {
		return {
			loading: false,
			step: 0,
			snackbarError: false,
			questions: formatConfig(this.$srv('TW_QuestionsAndAnswers')),
			isLastStepValid: false,
			stopDialog: false,
		}
	},
	methods: {
		selectOption(i, optionKey) {
			this.questions[i].active = optionKey
			if (optionKey == 'input') {
				this.questions[i].res = this.questions[i].input.value || ''
			} else {
				this.questions[i].res = this.questions[i].options[optionKey]
			}
		},
		close() {
			this.$store.set('whisky/memberDialog', false)
			this.$emit('click:close')
		},
	},
	computed: {
		fullHeight() {
			// if(this.$vuetify.brea)
			if (this.$vuetify.breakpoint.smAndDown) return false
			return this.step < this.questions.length || this.$vuetify.breakpoint.mdAndUp
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.h100vh {
	height: 100vh;
}
.tools {
	position: absolute;
	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		position: fixed;
		background-color: rgba(255, 255, 255, 0.8);
	}
	left: 0;
	top: 0;
	z-index: 1;
	width: 100%;
}
.center-y {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.v-stepper__content {
	padding: 12px !important;
}

.question,
::v-deep .question {
	font-family: 'Roobert Medium';
	max-width: 550px;
	text-align: center;
	line-height: 27pt;
	font-size: 25pt;
	@media #{map-get($display-breakpoints, 'md-and-up')} {
		line-height: 40pt;
		font-size: 30pt;
	}
}

.option {
	font-size: 18pt;
	line-height: 18pt;
	cursor: pointer;
	font-family: 'Roobert Medium';
	transition: color 0.3s;
	@media #{map-get($display-breakpoints, 'md-and-up')} {
		font-size: 20pt;
		line-height: 20pt;
	}
}
.option.active {
	color: var(--gold);
}
.option:hover {
	color: var(--gold);
}
</style>
