<template>
	<section v-if="data">
		<Container
			width="100%"
			max-width="1440px"
			class="pa-0 d-flex flex-column-reverse flex-md-row justify-center align-center"
		>
			<div class="flex-grow-1 d-flex flex-column align-center justify-center text relative">
				<div>
					<Media
						max-width="90vw"
						:width="$vuetify.breakpoint.mdAndDown ? '400' : null"
						:src="data.logo"
					/>
				</div>
				<span class="mt-6">{{ data.text }}</span>
				<WButton class="mt-2" @click="$emit('click:action-btn')" v-if="!isMember">
					Quiero ser miembro
				</WButton>
				<div v-else class="text-center font-weight-bold">
					Bienvenido {{ firstname }}, ya sos miembro de La Whiskería. <br />
					Disfrutá de todos nuestros beneficios!
				</div>
			</div>
			<div class="relative images">
				<Media :src="data.image" width="100%" min-height="400" max-height="80vh" cover />
			</div>
		</Container>
	</section>
</template>

<script>
export default {
	props: {
		firstname: String,
		isMember: Boolean,
	},
	computed: {
		data() {
			return this.$srv('TW-A-Header')
		},
	},
}
</script>

<style lang="scss" scoped>
.text {
	width: 50%;
	left: 70px;
	z-index: 3;
	@media screen and (max-width: 960px) {
		width: 100%;
		left: 0%;
		top: -80px;
	}
}
.images {
	width: 70%;
	@media screen and (max-width: 1200px) {
		width: 55%;
	}
	@media screen and (max-width: 960px) {
		width: 100%;
		height: 400px;
	}
}
</style>
