<template>
	<div class="d-flex flex-column justify-center align-center">
		<div class="gold--text question text-uppercase mb-2">Completá tus datos</div>
		<div class="mb-4">{{ questions.length + 1 }} / {{ questions.length + 1 }}</div>
		<form @submit.prevent="submit">
			<Validator :validation="validation">
				<v-row>
					<v-col cols="12" md="6">
						<TextField label="Nombre" v-model="form.firstname" validator-key="user.firstname" />
					</v-col>
					<v-col cols="12" md="6">
						<TextField label="Apellido" v-model="form.lastname" validator-key="user.lastname" />
					</v-col>
					<v-col cols="12" md="7">
						<TextField
							label="Email de Contacto"
							v-model="form.contactEmail"
							validator-key="user.contactEmail"
						/>
					</v-col>
					<v-col cols="12" md="5">
						<TextField
							label="Nro. de documento (DNI)"
							v-model="form.idNumber"
							validator-key="member.idNumber"
						/>
					</v-col>
					<v-col cols="12" md="5">
						<CountryPhoneCodeSelector
							v-model="form.phoneCountryId"
							validator-key="user.phoneCountryId"
						/>
					</v-col>
					<v-col cols="4" md="2">
						<TextField
							label="Cód. Área"
							v-model="form.phoneAreaCode"
							validator-key="user.phoneAreaCode"
						/>
					</v-col>
					<v-col cols="8" md="5">
						<TextField label="Teléfono" v-model="form.phoneNumber" validator-key="user.phoneNumber" />
					</v-col>
					<v-col cols="12">
						<Textarea
							label="Tu dirección"
							placeholder="Seleccionar..."
							rows="1"
							auto-grow
							readonly
							no-resize
							:value="form.address ? makeAddressLine(form.address) : ''"
							@click="addressDialog = true"
							validator-key="member.address"
						/>
						<div class="text-right mt-1">
							<Button small text @click="addressDialog = true">Seleccionar</Button>
						</div>
					</v-col>
				</v-row>
			</Validator>
			<v-tooltip bottom :disabled="isValid">
				<template v-slot:activator="{ on, attrs }">
					<div v-bind="attrs" v-on="on" class="mt-4 mt-md-8">
						<WButton type="submit" x-large block :loading="loading" :disabled="!isValid">
							Solicitar membresia
						</WButton>
					</div>
				</template>
				<span class="font-0">Complete el paso actual para continuar</span>
			</v-tooltip>
		</form>
		<AddressDialog
			v-model="addressDialog"
			@addressSelected="form.address = $event"
			title="Ingresa tu dirección"
		/>
	</div>
</template>

<script>
import { get } from 'vuex-pathify'
import addressHelperMixin from '@/__shared/shop/mixin_address-helper.js'
export default {
	mixins: [addressHelperMixin],
	props: {
		questions: Array,
	},
	data() {
		return {
			addressDialog: false,
			loading: false,
			form: {},
			validation: {},
		}
	},
	methods: {
		submit() {
			let survey = Object.values(this.questions).map(({ question, res }) => ({ q: question, a: res }))
			this.$shopApi.post('/member-form', {
				loading: (v) => (this.loading = v),
				data: { ...this.form, survey },
				onValidation: ({ validation }) => {
					this.validation = validation
				},
				done: ({ success, message, options }) => {
					if (success) {
						options.abort()
						window.location.href = this.$router.resolve({
							query: { 'member-form-success': null },
						}).href
					} else if (message?.code == 'ALREADY_MEMBER') {
						options.abort()
						window.location.href = String(window.location.href)
					}
				},
			})
		},
	},
	computed: {
		isValid() {
			return !Object.values(this.form).find((v) => !v)
		},
		user: get('shop/user'),
	},
	created() {
		this.form = {
			firstname: this.user.firstname,
			lastname: this.user.lastname,
			contactEmail: this.user.contactEmail,
			phoneCountryId: this.user.phoneCountryId,
			phoneAreaCode: this.user.phoneAreaCode,
			phoneNumber: this.user.phoneNumber,
			address: null,
			idNumber: null,
		}
	},
}
</script>

<style></style>
