<script>
import { get } from 'vuex-pathify'
export default {
	metaInfo: {
		title: 'La Whiskería',
	},
	pageView: true,
	computed: {
		dateSaved: get('whisky/dateSaved'),
		isMember: get('shop/user@member'),
		user: get('shop/user'),
	},
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<div>
		<TW-A-Header
			:isMember="isMember"
			:firstname="user ? user.firstname : ''"
			@click:action-btn="$store.set('whisky/memberDialog', true)"
		/>
		<TW-B-MembersOnly :isMember="isMember" class="mt-md-6" @click:action-btn="entryDialog = true" />
		<TW-C-YourPlace :isMember="isMember" @click:action-btn="$store.set('whisky/memberDialog', true)" />
		<TW-D-OurSelection />
	</div>
</template>

<style scoped>
::v-deep .v-dialog {
	border-radius: 0 !important;
	word-spacing: 5px;
}
.logo-background {
	position: absolute;
	left: 50%;
	top: 11%;
	transform: translateX(-50%);
}
.close-login-dialog-btn {
	position: absolute;
	top: 10px;
	right: 10px;
}
</style>
