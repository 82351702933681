<script>
import logo from '../assets/backgroundlogo.svg'
export default {
	props: {
		isMember: Boolean,
	},
	data: () => ({
		logo,
		items: [
			//'Descuentos',
			//'Posibilidad de pagar en efectivo contra entrega en CABA y GBA',
			'Promociones especiales',
			'Novedades del whisky en argentina y en el mundo',
			'Envíos sin cargo',
		],
	}),
}
</script>
<template>
	<section class="pb-16">
		<div class="text-center text-uppercase d-flex flex-column justify-center align-center">
			<Media :src="logo" contain width="200px" class="mb-3" />
			<div class="mb-5 ff-1-regular font-2 font-sm-4">
				Esta es la whiskería, tu club. <br />
				Tu lugar de pertenencia.
			</div>
			<div class="mb-16 ff-1-regular font-2 font-sm-4" v-show="!isMember">
				Queremos que formes parte de LA WHISKERÍA. <br />
				Para ello ingresá tus datos personales y comenzá a disfrutar <br />
				de todos los beneficios pensados para vos.
			</div>
			<div>
				<ul class="mb-16 pl-0">
					<li v-for="(item, i) of items" :key="i" style="max-width: 700px" class="mb-6">
						<div class="font-weight-bold ff-2-regular font-3 font-sm-5 font-md-7">
							{{ item }}
						</div>
					</li>
				</ul>
				<WButton x-large @click="$emit('click:action-btn')" v-if="!isMember">
					Quiero ser miembro
				</WButton>
			</div>
		</div>
	</section>
</template>

<style scoped lang="scss">
.maxW-500 {
	max-width: 500px;
}
.background {
	position: absolute;
	left: 0%;
	top: -20px;
	height: 100%;
	width: 100%;
}
.background-image {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
}
</style>
